import { render, staticRenderFns } from "./main-sidebar-sub-menu.vue?vue&type=template&id=09eeec96"
import script from "./main-sidebar-sub-menu.vue?vue&type=script&lang=js"
export * from "./main-sidebar-sub-menu.vue?vue&type=script&lang=js"
import style0 from "./main-sidebar-sub-menu.vue?vue&type=style&index=0&id=09eeec96&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <div>
    <aside
      :class="['aui-sidebar', `aui-sidebar--${$store.state.sidebarLayoutSkin}`]"
    >
      <div class="aui-sidebar__inner">
        <el-menu
          :default-active="$store.state.sidebarMenuActiveName"
          :collapse="$store.state.sidebarFold"
          :unique-opened="true"
          :collapseTransition="false"
          class="aui-sidebar__menu"
        >
          <sub-menu
            v-for="menu in $store.state.sidebarMenuList"
            :key="menu.id"
            :menu="menu"
          />
        </el-menu>
        <div
          class="version"
          style="
            color: #8a979e;
            font-size: 10px;
            text-align: center;
            transform: scale(0.8);
            margin-top: 100px;
          "
        >
          ADMIN.VERSION: {{ version }}
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import SubMenu from './main-sidebar-sub-menu';

//  console.log('ADMIN.VERSION:', process.env.version);
export default {
  data() {
    return {
      version: process.env.version,
    };
  },
  components: {
    SubMenu,
  },
  created() {
    this.$store.state.sidebarMenuList = window.SITE_CONFIG['menuList'];
  },
};
</script>
